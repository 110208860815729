import React from 'react'
import mainban  from '../../assets/images/banner/mainban.jpg'

const Bannar = () => {
  return (
<div class="bg-cover bg-center  h-auto text-white py-24 px-10 object-fill" 
style={{ backgroundImage: `url(${mainban})` }}>
       <div class="md:w-1/2 ">
        <p class="font-bold text-sm uppercase">GET THE BEST!</p>
        <p class="text-3xl font-bold">QUALITY PRODUCTS</p>
        <p class="text-2xl mb-10 leading-none">AFFORDABLE PRICES</p>
        <a href="#" class="bg-green-800 py-4 px-8 text-white font-bold uppercase text-xs rounded hover:bg-gray-200 hover:text-gray-800">CONTACT US TO BUY</a>
        </div>  
    </div>
      )
}

export default Bannar