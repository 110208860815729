import React, { useState } from 'react'
import sale1 from '../../../assets/images/sale/sale1.jpg'
import sale2 from '../../../assets/images/sale/sale2.jpg'
import sale3 from '../../../assets/images/sale/sale3.jpg'
import sale4 from '../../../assets/images/sale/sale4.jpg'
import sale5 from '../../../assets/images/sale/sale5.jpg'
import sale6 from '../../../assets/images/sale/sale6.jpg'
import sale7 from '../../../assets/images/sale/sale7.jpg'
import sale8 from '../../../assets/images/sale/sale8.jpg'
import sale9 from '../../../assets/images/sale/sale9.jpg'
import sale10 from '../../../assets/images/sale/sale10.jpg'
import sale11 from '../../../assets/images/sale/sale11.jpg'
import { Link } from 'react-router-dom'



const Tpsale = () => {
  return (
<div className='grid px-2 py-2 w-full sm:grid-cols-1 justify-items-center md:grid-cols-2  lg:grid-cols-3 gap-7 align 'id='top_pick'>
       
    
       <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale1} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">COCONUT CHIPS </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
            {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><Link to="/shop">CONTACT US TO BUY</Link></span> */}

   
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale2} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">OVEN DRIED SMOKED CATFISH</div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='/'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale3} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">GARLIC & PAPER POWDER</div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='/'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale4} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">ABEERE POWDER </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='/'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale5} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">PURE COCONUT OIL</div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">   <Link to="/contact">CONTACT US TO BUY</Link></span> */}
        
              
            </div>
          </div>

          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale6} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">CINNAMON POWDER </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='/'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          
          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale7} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">SHEA BUTTER </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='https://www.youtube.com/watch?v=Wc_Hy-HokSc'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          
          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale8} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">PURE HONEY </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='https://www.youtube.com/watch?v=Wc_Hy-HokSc'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          
          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale9} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">OFADA RICE </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='https://www.youtube.com/watch?v=Wc_Hy-HokSc'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          
          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale10} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">VIRGIN COCONUT OIL </div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='https://www.youtube.com/watch?v=Wc_Hy-HokSc'>ADD TO CART</a></span> */}
              
            </div>
          </div>

          
          <div class="max-w-sm rounded overflow-hidden shadow-lg w-full hover:animate-pulse">
            <img src={sale11} style={{height:260}} alt="Sunset in the mountains"className='w-full'/>
            <div class="px-6 py-4">
              <div class="font-bold text-xl mb-2">BLACK SEED</div>
              {/* <p class="text-gray-700 text-base">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatibus quia, nulla! Maiores et perferendis eaque, exercitationem praesentium nihil.
              </p> */}
            </div>
            <div class="px-6 pt-4 pb-2">
              {/* <span class="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2"><a href='https://www.youtube.com/watch?v=Wc_Hy-HokSc'>ADD TO CART</a></span> */}
              
            </div>
          </div>

    </div>  )
}

export default Tpsale